
       .highlightscontainer {
                margin: 0;
                padding: 0;
                width: 100%;
                height: 100%;
                text-align: center;
        }

        .highlights-header {     
                position: fixed;
                z-index: 999;
                height: 56px;
                width: 100%;   
                background: #FFF;         
        }

        .highlights-header .highlights-headerwrapper {
                display:flex;
                align-items:center;
                justify-content:space-between;
                padding: 10px 14px;    
             
        }

        .highlights-headerwrapper .headerlogo{
                display: block;
                margin: 0px auto;
           }
   
           .headerlogo img{
               max-width:130px;
               max-height:40px;
               object-fit: cover;
               object-position:center center;
           }
   
           .highlights-headerwrapper .backbox{
               display: flex;
               align-items: center;
               justify-content: center;
               gap: 10px;
               flex-shrink: 0;
               
           }

         .highlights-section-wrapper {
                        z-index: 99;
                        margin-bottom: 30px;
                        padding-top: 50px;
            }


        .highlights-section {
                margin: 0 auto 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                gap: 4px;
                width: 100%;
                height: 100%;
        }

        .highlights-section .highlights-section-img {
                margin: 0 auto;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;   
                width: 100%;
                height: 38vh;
        
        }

        .highlights-section  .highlights-section-content {
                        padding: 8px 15px;
                        display: flex;
                        flex-direction: column;
                        gap: 13px;
                        position: relative;
        }

       .highlights-section-content .highlights-despcontent {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: flex-start;
        }

        .highlights-section-content .highlights-despcontent h3{
                        text-align: left;
                        color: #0F0005;
                        font-family: Poppins;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        letter-spacing: 1.12px;
        }

        .highlights-section-content .highlights-despcontent p {
                                margin-top: 8px;
                                text-align: left;
                                color: #656B76;
                                font-family: Montserrat;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 22px;
                                letter-spacing: 0.96px;
        }

        .highlights-section-content .viewmorebtn {
                               
                                display: flex;
                                align-items: flex-end;
                                justify-content: flex-end;
                                align-self: flex-end;
                                padding: 10px 14px;
                                border-radius: 6px;
                                background: #040404;
                                cursor: pointer;
                                color: #FFF;
                                font-family: Montserrat;
                                font-size: 12px;
                                font-weight: 500;
                                line-height: 22px;
                                letter-spacing: 1px;
                                border: 1px solid #040404;
        }


        /* highlights details section. */

                        .highlightsdetails-container{
                                margin: 0;
                                padding: 0;
                                width: 100%;
                                height: 100%;
                                text-align: center;
                           }

                           .hddetails {
                                z-index: 99;
                                padding-top: 50px;
                        }
                
                
                    .hddetails-wrapper{
                          width: 100%;
                          height: 100%;
                          padding: 0px;
                          display: flex;
                          flex-direction: column;
                          gap: 10px;
                          align-items: center;
                          justify-content: center;
                          margin: 0 auto;
                        
                  }
                
                       .hddetails-wrapper .hdpicturecontent{
                                        width: 100%;
                                        height: 100%;
                                        padding: 0px;
                           }
                
                                .hdpicturecontent  .hditem{
                                        width: 100%;
                                        height: 100%;
                                        overflow: hidden;
                                        position: relative;
                                }
                
                                .hdpicturecontent  .hditem img {
                                        width: 100%;
                                        height: 45vh;
                                        object-fit: cover;
                                        object-position:50% 50%;
                                }
                
                                .hdpicturecontent  .hditem video{
                                        width: 100%;
                                        height: 45vh;
                                        object-fit: cover;
                                        object-position:50% 50%;
                                }
                
                
                        .hdpicturecontent .hdarrowsbox{      
                                width: 100%;
                                height: 15px;
                                margin-top: -30px;
                                position: absolute;
                                /* bottom: 30px; */
                                display: flex;
                                align-items: center;
                                justify-content:center;
                                gap: 10px;     
                        }
                
                          .hdarrowsbox .hddotsui{
                                width: 10px;
                                height: 10px;
                                border-radius: 3px;
                                background: #D4D4D4;
                          }
                
                
                    .hddetails-wrapper  .hdtext-content{
                                padding: 10px 17px;
                                text-align: left;
                    }
                
                
                    .hddetails-wrapper  .hdtext-content h3{
                                color: #0F0005;
                                font-family: Poppins;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                                letter-spacing: 1.12px;
                    }
                
                    .hddetails-wrapper  .hdtext-content p{
                            margin-top: 10px;
                            color: #040404;
                            font-family: Montserrat;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 22px; /* 183.333% */
                            letter-spacing: 0.96px;
                    }






   










 

