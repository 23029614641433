
    .orgcontainer{
        margin: 0;
        padding: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.876);
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        background-position:46% 50%;
        text-align: center;
        box-sizing: border-box;
        z-index: 999;
 
    }

    .bgoverlay{
        position:absolute;
        background-color: rgba(0, 0, 0, 0.316);
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        z-index: 99;
    
    }

    .logo-wrapper {
        margin: 15px 10px;
        display:flex;
        align-items: flex-start;
        justify-content: space-between;
        /* gap: cal(100%-50%); */
        overflow: hidden;   
    }
    

    .logo-wrapper #logothumbnail {
                max-width:142px;
                max-height:65px;
                object-fit: cover;
                object-position:50% 50%;
    }

    .logo-wrapper  #eventthumbnail{
                max-width:142px;
                max-height:65px;
                object-fit: cover;
                object-position:50% 50%;

    }

    .menulistwrapper{
            position: absolute;
            bottom: 150px;
            width: 100%;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: center;
    }


    .menulistwrapper   .menulist {
            width: 100%;
            height: auto;
            text-align: center;
            margin: 0px 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 30px;
            z-index: 19;
            background-color: rgba(0, 0, 0, 0.215);
            border-radius: 6px;
     
    }

    #menubtn:hover{
          font-weight: 500;
          background: #F2F2F2;
    }

      .menulist .lineone {
            width: 100%;
            height: auto;
            display: flex;
            align-items: center;
            justify-content:center;
            gap: 25px;
            margin: 8px 15px 0px;
    }

      .menulist .linetwo {
            width: 100%;
            height: auto;
            display: flex;
            align-items: center;
            justify-content:center;
            gap: 25px;
            margin: 15px 10px;

       }

    .icon-item{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex-shrink: 0; 
        gap: 8px; 
        width: 69px;
        height: 69px;   

    }

    .icon-wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0.481px solid #FFF; 
        border-radius: 100%;
        padding: 8px;
    }

        .icon-text{
          
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            white-space: nowrap;
            
        }


        .icon-text h3{
            color: #FFF;
            font-family: Montserrat;
            font-size: 10.865px;
            font-style: normal;
            font-weight: 800;
            line-height: 12px; /* 8.649px */
            letter-spacing: 0.544px;
        }

        .poweredbylogowrapper{
            position: absolute;
            right: 10px;
            bottom: 50px;
            width: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            
        }

        .poweredbylogowrapper  #poweredbylogo {
            max-width:180px;
            max-height:65px;
            object-fit: cover;
            object-position:50% 50%;
        } 


      @media only screen and 
        (min-device-width: 768px) and (max-device-width: 1024px) and (min-resolution: 2dppx),
        (min-device-width: 834px) and (max-device-width: 1194px) and (min-resolution: 2dppx)
        {
      
            .menulistwrapper   .menulist {
              flex-direction: row;
               gap: 0px;
               margin: 0px 30px;
               width: 79vw;
               padding: 15px 0px;
            }

           .menulist .lineone {
                margin: 8px 15px 0px 15px;
           }

            .menulist .linetwo {
               margin: 8px 0px 0px 0px;
           }

                .logo-wrapper #logothumbnail {
                        max-width:240px;
                        max-height:165px;
                }

                .logo-wrapper  #eventthumbnail{
                        max-width:240px;
                        max-height:165px;
                }

                .poweredbylogowrapper{
                    bottom: 80px;  
                }

    } 
    
    @media only screen and 
    (min-device-width: 1024px) and (max-device-width: 1366px) and (min-resolution: 2dppx) {
        
        .menulistwrapper   .menulist {
            flex-direction: row;
             gap: 0px;
             margin: 0px 30px;
             width: 60vw;
             padding: 15px 0px;
          }

         .menulist .lineone {
              margin: 8px 15px 0px 10px;
         }

          .menulist .linetwo {
             margin: 8px 0px 0px -25px;
         }

         .logo-wrapper #logothumbnail {
                    max-width:240px;
                    max-height:165px;
        }

        .logo-wrapper  #eventthumbnail{
                    max-width:240px;
                    max-height:165px;
        }

        .poweredbylogowrapper{
            bottom: 80px;    
        }
    }  