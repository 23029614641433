
    .productscontainer {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        text-align: center;
     }
 
    .products-section-wrapper {
                z-index: 99;
                padding: 65px 10px 30px;
                background-color: lightgrey;
    }
    
    .prodcard {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column; 
    }

    .prodcard .prodthumbnail{
                max-width: 45vw;
                max-height: 25vh; 
                padding: 8px;  
                overflow: hidden;
    }

    .prodcard .prodthumbnail img{           
                max-width: 100%;
                max-height: 100%; 
                object-fit:fill;
                object-position:center center;
    }

        .prodcard .titlebox{
            text-align: left;
            padding: 10px 0px;
            margin-left: 6px;
        }
        
        .prodcard .titlebox h4 { 
            color: #0F0005;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.96px;
        }

    
     /* Product Details styling */
    
            .productcarousalcontainer{
                margin: 0;
                padding: 0;
                width: 100%;
                height: 100%;
            }
    
            .productcarousalcontainer .productcarousal-wrapper{
                z-index: 99;
                padding-top: 50px;
                padding-bottom: 30px;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                gap: 10px;
                align-items: flex-start;
                justify-content:flex-start;
            }

            .productdetails-content .changepvbox{                
                        display: flex;
                        align-items: flex-start;
                        justify-content:flex-start;
                        flex-direction: column;
                        margin-top: 7px;
                        text-align: left;
               }

              .changepvbox h4 {
                                margin-left: -1px;
                                color: #040404;
                                font-family: Montserrat;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                letter-spacing: 1.12px;
              }


        .productcarousal-wrapper  .pvcarousalitem {
                        width: 100%;
                        height: 100%;
                        padding: 0px;
                        margin: 0px;
                    }


            .pvcarousalitem .productvarientwrapper {
                            width: 100%;
                            height: 100%;
                            overflow: hidden;
                            position: relative;
                                       
            }

                .productvarientwrapper img{
                            width: 100%;
                            height: 54vh;
                            object-fit: cover;
                            object-position:center center;
                            display: block; 
                }


                .productvarientwrapper video {
                            width: 100%;
                            height: 54vh;
                            object-fit: cover;
                            object-position:center center;
                            display: block;    
               }


                 .pvcarousalitem  .carousalarrows {
                            margin-top:-30px;
                            padding:0px;
                            width: 100%;
                            height: 20px;
                            text-align: center;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content:center;
                            gap: 10px;
                            position: absolute;
                            /* left: calc(100% - 65%);
                            top: calc(100% - 56%); */
                          
               }

               .productdetails-content   .icon-box {
                            display: flex;
                            align-items:flex-start;
                            justify-content:flex-start;
                            gap: 10px;
               }


               .productdetails-content .icon-box .icon-wrapper { 
                            padding:10px;
                            border-radius: 10px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: #fff;
                            color: #000;
               }
                     


                 .pvcarousalitem .carousalarrows  .indicator{
                       border-radius: 3px;
                       cursor: pointer;
                       box-shadow: 1px 1px 1px gray;
               }


            .productcarousal-wrapper .productdetails-content {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        align-items: flex-start;
                        justify-content:flex-start;
               }


               .productdetails-content  .pvcolorpalet h3{
                    margin-top: 5px;
                    color: #040404;
                    font-family: Montserrat;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 1.12px;
           }

           .productdetails-content  .pvcolorpalet .pvcolorbox{
                    margin-top: 8px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: row;
                    gap: 12px;     
               }
               

               .pvcolorpalet .pvcolorbox img{
                        width: 30px;
                        height: 30px;            
               }


                .pvcolorbox  img:focus {
                      border: 2px solid black;
                      border-radius: 6px;
                    
                  }

                  .productdetails-content{
                         padding: 0px 20px 10px;
                  }


               .productdetails-content .pvdecpbox h3{
                color: #0F0005;
                font-family: Montserrat;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 1.12px;
           }

               .productdetails-content .pvdecpbox p {
                        margin-top: 8px;
                        color: #040404;
                        font-family: Montserrat;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22px; /* 183.333% */
                        letter-spacing: 0.96px;
               }

               .productdetails-content .pvdecpbox #additionalinfo{
                        color: #0F0005;
                        font-family: Poppins;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        letter-spacing: 1.12px;
                        margin-bottom: 10px;
               }

               .productdetails-content  .pvdecpbox  #prodprice{
                        margin-top: 5px;
                        color: #0F0005;
                        font-family: Montserrat;
                        font-size: 17px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        letter-spacing: 1.28px;
               }
               
                    .pvdecpbox  #prodprice #Strikedoutprice{
                        text-decoration: line-through;
                        color:red;
                        font-family: Montserrat;
                        font-size: 17px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        letter-spacing: 1.28px;
                        margin-left: 20px;
                    }


               .pvfeatureslist h3 { 
                margin-top: 5px;
                color: #040404;
                font-family: Montserrat;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: 1.12px;
               }

               .pvfeatureslist li{
                margin: 8px 10px 0px;
                color: #656B76;
                font-family: Montserrat;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.96px;
               }
    

               .pvsizebox h3{
                        margin-top: 5px;
                        color: #040404;
                        font-family: Montserrat;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        letter-spacing: 1.12px;
               }


               .pvsizebox p{
                        margin-top: 8px;
                        color: #656B76;
                        font-family: Poppins;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: 1.12px;
               }



          @media only screen and 
           (min-device-width: 768px) and (max-device-width: 1024px) and (min-resolution: 2dppx),
           (min-device-width: 834px) and (max-device-width: 1194px) and (min-resolution: 2dppx)
           {
                    .productvarientwrapper img{
                            width: 100%;
                            height: 65vh;
                    }

                    .productvarientwrapper video {
                          width: 100%;
                          height: 65vh;      
                    }

                    .prodcard .titlebox h4{
                        font-size: 22px;
                    }

                    .prodcard .prodthumbnail{
                        max-height: 32vh; 
                    }
       
           } 
           
           @media only screen and 
           (min-device-width: 1024px) and (max-device-width: 1366px) and (min-resolution: 2dppx) {
               
                    .productvarientwrapper img{
                        width: 100%;
                        height: 65vh;
                    }

                    .productvarientwrapper video {
                        width: 100%;
                        height: 65vh;
                  }

                  .prodcard .titlebox h4{
                    font-size: 22px;
                  }

                   .prodcard .prodthumbnail{
                    max-height: 32vh; 
                  }
       
           }