
        .eventdetails-container{
            margin: 0;
            padding: 0;
            width: 100%;
            height: 100%;
            text-align: center;
        }


        .eventdetailssection{
            z-index: 99;
            width: 100%;
            height: 100%;
            padding-top: 60px;
            padding-bottom: 35px;
        }


      .eventdetails-wrapper {
                width: 100%;
                height: 100%;
                padding: 0px;
                display: flex;
                flex-direction: column;
                gap: 10px;
                align-items: center;
                justify-content: center;
                margin: 0 auto;
        }

        .eventdetails-wrapper  .edpicturecontent{
                    width: 100%;
                    height: 100%;
                    padding: 0px;
        }

            .edpicturecontent  .editem{
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    position: relative;
            }

            .edpicturecontent  .editem img {
                    width: 100%;
                    height:52vh;
                    object-fit: cover;
                    object-position:50% 50%;
            }

            .edpicturecontent  .editem video{
                    width: 100%;
                    height: 52vh;
                    object-fit: cover;
                    object-position:50% 50%;
            }
              
        .edpicturecontent .arrowsbox{      
            width: 100%;
            height: 15px;
            margin-top: -30px;
            position: absolute;
            /* bottom: 30px; */
            display: flex;
            align-items: center;
            justify-content:center;
            gap: 10px;     
        }

        .arrowsbox .dotsui{
            border-radius: 3px;
            cursor: pointer;
            box-shadow: 1px 1px 1px gray;
        }


        .eventdetails-wrapper  .eventtext-content{
            padding: 10px 17px;
            text-align: left;
        }


        .eventdetails-wrapper  .eventtext-content h3{
            color: #0F0005;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 1.12px;
        }

        .eventdetails-wrapper  .eventtext-content p{
            margin-top: 10px;
            color: #040404;
            font-family: Montserrat;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 183.333% */
            letter-spacing: 0.96px;
        }


.eventdetails-wrapper  .eventtext-content  .eventdtcontainer{
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                gap: 40px;
}

.eventdetails-wrapper  .eventtext-content  .eventdtcontainer .event-date, .event-time {
                margin-top: 10px;
                padding: 8px;
}


.eventdetails-wrapper  .eventtext-content  .eventdtcontainer .event-date h3{
                    color: #0F0005;
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: 1.12px;
}

.eventdetails-wrapper  .eventtext-content  .eventdtcontainer .event-time h3{
                    color: #0F0005;
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: 1.12px;
}

.eventdetails-wrapper  .eventtext-content  .eventdtcontainer .event-date p {   
                    margin-top: 10px;
                    color: #040404;
                    font-family: Montserrat;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px; /* 183.333% */
                    letter-spacing: 0.96px;
}

.eventdetails-wrapper  .eventtext-content  .eventdtcontainer .event-time p{   
                    margin-top: 10px;
                    color: #040404;
                    font-family: Montserrat;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px; /* 183.333% */
                    letter-spacing: 0.96px;
 }

.eventdetails-wrapper  .eventtext-content  .event-venue {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                margin-top: 8px;
                padding: 8px;
}

.eventdetails-wrapper  .eventtext-content  .event-venue h3 {
                        color: #0F0005;
                        font-family: Poppins;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        letter-spacing: 1.12px;
 }

 
.eventdetails-wrapper  .eventtext-content  .event-venue p {
                    margin-top: 10px;
                    color: #040404;
                    font-family: Montserrat;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px; /* 183.333% */
                    letter-spacing: 0.96px;
    }
