
     .abdetails-container{
                margin: 0;
                padding: 0;
                width: 100%;
                height: 100%;
                text-align: center;
  }

        .abdetails {
                z-index: 99;
                width: 100%;
                height: 100%;
                padding-top: 50px;
                margin-bottom: 30px;
        }

    .abdetails-wrapper{
          width: 100%;
          height: 100%;
          padding: 0px;
          display: flex;
          flex-direction: column;
          gap: 10px;
          align-items: center;
          justify-content: center;
          margin: 0 auto;
        
  }

    .abdetails-wrapper  .abpicturecontent{
                        width: 100%;
                        height: 100%;
                        padding: 0px;
             }

                .abpicturecontent  .abitem{
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                        position: relative;
                }

                .abpicturecontent  .abitem img {
                        width: 100%;
                        height: 45vh;
                        object-fit: cover;
                        object-position:50% 50%;
                }

                .abpicturecontent  .abitem video{
                        width: 100%;
                        height: 45vh;
                        object-fit: cover;
                        object-position:50% 50%;
                }


        .abpicturecontent .arrowsbox{      
                width: 100%;
                height: 15px;
                margin-top: -30px;
                position: absolute;
                /* bottom: 30px; */
                display: flex;
                align-items: center;
                justify-content:center;
                gap: 10px;     
        }

          .arrowsbox .dotsui{
                border-radius: 3px;
                cursor: pointer;
                box-shadow: 1px 1px 1px gray;
          }


    .abdetails-wrapper  .abtext-content{
                padding: 10px 17px;
                text-align: left;
    }


    .abdetails-wrapper  .abtext-content h3{
                color: #0F0005;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 1.12px;
    }

    .abdetails-wrapper  .abtext-content p{
            margin-top: 10px;
            color: #040404;
            font-family: Montserrat;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 183.333% */
            letter-spacing: 0.96px;
    }

      