.hotspotcontainer {
       margin: 0 auto;
       text-align: center;
       /* padding: 15px; */
  }

  .carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    width:100%;
    height:100%;
  }

  .image-container {
    position: relative;
    overflow: hidden;
    display: inline-block;
    width:100%;
    height:100%;
  }
  
  .image-container img{
    width:calc(100% - 12%); 
    height:calc(100% - 12%); 
    object-fit: cover;
    object-position: 50% 50%;
    
  }

  .hotspot {
    width: 100%;
    height: 100%;
    position: absolute;
    width: 18px;
    height: 18px;
    background-color:blue;
    border: none;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .popup {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    overflow: hidden;
    border-radius: 6px;
  }
  

     .popup img{
      flex: 1;
      width: 45vw;
      height: 40vh;
      object-fit: contain;
      object-position: 50% 50%;
  }

     .popup p{
        flex: 1;
        padding: 10px;
        overflow-y: scroll;
        color: #040404;
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 183.333% */
        letter-spacing: 0.96px;
     }