
        .gallery-container{
            margin: 0;
            padding: 0;
            width: 100%;
            height: 100%;
        }
   

        .gallery-wrapper{
                z-index: 99;
                padding-top: 60px;
        }
            
        .gallery-grip {
            background-color: lightgrey;
            padding: 8px 8px 30px;
            display:grid;
            grid-template-columns: repeat(3,1fr);
            grid-auto-rows: 30vw 30vw;
            grid-gap:4px;
            grid-auto-flow: dense;
        }

        .gallery-grip   .gallery-item {  
            width:100%;
            height:100%;
            cursor:pointer;  
            background-color: #fff;
            /* border: 0.4px solid lightgrey; */
           
        }

        .gallery-item  .imgwrapper  {
            width:100%;
            height:100%;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
        }


        .galimgwrapper {
            margin: 0px auto;
            width: 100%;
            height: 100%;
            overflow: hidden;
            box-sizing: border-box;
        }

        .galimgwrapper  #image {            
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position:50% 50%;
        }

        .galimgwrapper  video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position:50% 50%;
        }

        .gallery-item  .mediawrapper{
            width:100%;
            height:100%;
           overflow: hidden;
        }


        .mediawrapper  video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position:50% 50%;
        }


        #customized-dialog-title {
            color: #000;
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 1.12px;
            border-bottom: 0.4px solid rgba(62, 62, 62, 0.64);
        }


        #dialog-footer{
            border-top: 0.4px solid rgba(62, 62, 62, 0.64);
         
        }

        .dialog-footer-wrapper{
                margin: 0px auto;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 75px;
        }

        .dialog-footer-wrapper  .prenextbtnbox{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
        }


        .dialog-footer-wrapper  .prenextbtnbox .prebtn {
          
                 display: flex;
                 align-items: center;
                 justify-content: center;
                 border-radius: 6px;
                 padding: 6px 8px; 
                 background-color: #fff;
        }


        .dialog-footer-wrapper  .prenextbtnbox .nextbtn {
             
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 6px;
            padding: 6px 8px; 
            background-color: #fff;
   }


       .paginationbox{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 30px 10px;
            border-top: 0.4px solid rgba(0, 0, 0, 0.48);
            background: #F7F7F7;
       }
      
