
.contactus-container{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}


.contactus-wrapper{
        z-index: 99;
        padding-top: 56px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-items:flex-start;
        justify-content:flex-start;
      
}

.contactus-wrapper .picturecontent{
        width: 100%;
        height: 33vh;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
}


.contactus-wrapper .text-content{
        padding: 10px 15px;
        text-align: left;
        display: flex;
        flex-direction: column;
        align-items:flex-start;
        justify-content:flex-start;
        gap: 10px;

}

        .text-content .address{
            padding: 5px;
        }

    .text-content .address h3{
            color: #293241;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 1.12px;
    }

        .text-content .address p{
                     margin-top: 10px;
                    color: #040404;
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                    letter-spacing: 0.48px;
        }

    .text-content .Phoneandmailbox{
        margin-top: 8px;
        padding:5px;
        color: #040404;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: 0.48px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items:flex-start;
        justify-content:flex-start;
        text-align: left;
    
    }

        .Phoneandmailbox .phonebox  {
            display: flex;
            align-items:center;
            justify-content:center;
            text-align: left;
            gap: 10px;
        
        }

        .Phoneandmailbox .emailbox p {
              font-size: 14px;
              margin-top: -2px;  
        }

            .Phoneandmailbox .phonebox p {
                margin-top: -3px;  
                color: #700028;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: 0.48px;


          }

          .Phoneandmailbox .websitebox p {
                  margin-top: -3px;  
                  font-size: 14px;
          }

        .Phoneandmailbox .emailbox  {
       
            display: flex;
            text-align: left;
            align-items:center;
            justify-content: center;
            gap: 10px;
    
        }

        .Phoneandmailbox .websitebox {
            display: flex;
            text-align: left;
            align-items:center;
            justify-content: center;
            gap: 10px;
      
        }

    .text-content .socialmediabox {
                padding: 10px 10px 15px;
                color: #040404;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                letter-spacing: 1.12px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                gap: 15px;
    }

        .socialmediabox .iconbox {
                                display: flex;
                                align-items: flex-start;
                                justify-content: flex-start;
                                gap: 10px;
                                
        }

          .iconbox .facebookwrapper, .instagramwrapper .twitterwrapper, .youtubewrapper, .linkedinwrapper {  
                            display: flex;
                            align-items: center;
                            justify-content: center;
                         
         }


            .iconbox .facebookwrapper #facebookicon {          
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 24px;
                            height: 24px; 
                            padding: 8px;
                            border-radius: 12px;
                            border: 1px solid rgba(0, 0, 0, 0.12);        
        }

            .iconbox  .instagramwrapper #instagramicon  {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 24px;
                            height: 24px; 
                            padding: 8px;
                            border-radius: 12px;
                            border: 1px solid rgba(0, 0, 0, 0.12);    
        }

                .iconbox  .twitterwrapper #twittericon  {
                  
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 24px;
                    height: 24px; 
                    padding: 8px;
                    border-radius: 12px;
                    border: 1px solid rgba(0, 0, 0, 0.12);    
             }


     .iconbox  .youtubewrapper #youtubeicon  {
              
                display: flex;
                align-items: center;
                justify-content: center;
                width: 24px;
                height: 24px; 
                padding: 8px;
                border-radius: 12px;
                border: 1px solid rgba(0, 0, 0, 0.12);    
             }

             .iconbox  .linkedinwrapper #linkedinicon  {
              
                display: flex;
                align-items: center;
                justify-content: center;
                width: 24px;
                height: 24px; 
                padding: 8px;
                border-radius: 12px;
                border: 1px solid rgba(0, 0, 0, 0.12);    
             }


             #icontext {
                /* flex: 1; */
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 0.04em;
                color: #787878;

      }

 
     @media only screen and 
        (min-device-width: 768px) and (max-device-width: 1024px) and (min-resolution: 2dppx),
        (min-device-width: 834px) and (max-device-width: 1194px) and (min-resolution: 2dppx),
        (min-device-width: 1024px) and (max-device-width: 1366px) and (min-resolution: 2dppx) {
      
            .text-content .address h3{
                font-size: 18px;
           }
    
            .text-content .address p{
                        font-size: 16px;         
            }
    
            .text-content .Phoneandmailbox{
                font-size: 16px;
            }

            .text-content .socialmediabox #smtitle p{
                 font-size: 16px;
            }

    }
     