* {
    padding: 0px;
    margin: 0px;   
 }

    .arcontainer
    {
        width: 100%;
        height: 100%;
        text-align: center;
    }

    .loader{
        width: 60px;
        height: 60px;
        position: absolute;
        left: 50%;
        top: 50%;
        border-top: solid 2px blue;
        border-right: solid 2px blue;
        border-radius: 50%;
        animation-name: loader;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-direction:alternate;
      }
      
      @keyframes loader {
        
        0%{
            transform: rotate(0deg);
        }
        100%{
            transform: rotate(360deg);
        }
        
      }