*{
    padding: 0px;
    margin: 0px;
}

.brandlist {
    margin: 10px 0px 110px;
    padding:15px;
    text-align:center;
    box-sizing: border-box;
}

/* .gridlayout{
    display:grid;
    grid-template-columns: repeat(3,2fr);
    grid-auto-rows:200px 200px;
    gap: 25px;
    grid-auto-flow: dense;
    grid-row-gap: 115px;
} */

/* 
    .category-container{
   
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 35px;
    } */


.category-item { 
    width:100%;
    height:100%;
    position:relative;
    margin-bottom: 120px;
}

.category-item .category-image{
    flex: 3;
    width:100%;
    height:100%;
    overflow:hidden;
    background-color:rgb(9, 8, 8);
}

.category-item .category-image img{
    width: 90vw;
    height: 30vh;
    object-fit: cover;
    object-position:60% 50%;
    cursor:pointer;
    opacity: 0.64;
}

.category-item .category-text {
    flex: 1;
    text-align: left;
    color: #0F0005;
    position: absolute;
    top:100.75%;
    left: 0px;
    width: 100%;
    height: 80px;
    border-left: 0.3px solid #d8d8d8a4;
    border-bottom: 0.3px solid  #d8d8d8a4;
    border-right: 0.3px solid  #d8d8d8a4;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:35px;
    box-shadow: 1px 5px 3px #d1d0d0;
}

#logowrapper{
    /* padding: 10px; */
    /* margin-left:-27px; */
    width:100%;
    height:100%;
    overflow:hidden; 
    flex:2;
    display:inline-flex;
    align-items: center;
    justify-content: center;
}

#logowrapper img {
    max-width:139px;
    max-height:78px;
    object-fit: cover;
    object-position:50% 50%;
}

#explorebox {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.88em;
    text-align: center;
    letter-spacing: 0.1em;
    color: #A43607;
    flex:1;
    margin: 0px 15px 0px;
    display: flex;
    align-items:center;
    justify-content:center;  
    gap:8px
}

.category-item .onimgtext{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    top:36%;
    width: 94%;
    /* height: 100%; */
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 0.82em;
    letter-spacing: 0.2em;
    line-height:1.68em;
    padding: 0px 8px;
    color: #FFFFFF;
    
}
