
@import '~react-responsive-carousel/lib/styles/carousel.min.css';

    .threedcontainer{
        margin: 0px;
        padding: 0px;
        text-align: center;
    }

    .threedwrapper{
        padding: 15px 30px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: column;
        gap: 15px;
    
    }

    .threedwrapper .btnwrapper{
              display: flex;
              align-items: flex-end;
              justify-content: flex-end;
    }

        .threedwrapper .btnwrapper button {
                    padding: 8px 25px;
                    text-align: center;
                    border-radius: 6px;
                    background: #293241;
                    border: none;
                    color: #FFF;
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: 0.8px;
        }


    .threedwrapper   .carcarousalwrapper{
                width: 100%;
                height: 68vh;
                display: flex;
                align-items: center;
                justify-content: center;
               
    }

    .carcarousalwrapper  .carcarousalitem {
            width: 100%;
            height: 100%;
            overflow: hidden;
    }

    .carcarousalwrapper  .carcarousalitem img{
                width:100%;
                height:100%;
                object-fit: cover;
                object-position:50% 50%;
    }