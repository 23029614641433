
        .eventcontainer {
                margin: 0;
                padding: 0;
                width: 100%;
                height: 100%;    
        }

        .header {     
                position: fixed;
                z-index: 999;
                height: 56px;
                width: 100%;   
                background: #FFF; 
        
        }

        .header .headerwrapper {  
                display:flex;
                align-items:center;
                justify-content:space-between;
                padding: 10px 14px;    
                flex-shrink: 0;
        }


        .headerwrapper .headerlogo{
                display: block;
                margin: 0 auto;
        }


        .headerlogo img{
                max-width:130px;
                max-height:40px;
                object-fit: cover;
                object-position:center center;
        }

        .headerwrapper .backbox{
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
                flex-shrink: 0;
        }

        .event-section-wrapper {
                                z-index: 99;
                                margin-bottom: 30px;
                                padding-top: 60px;
        }

        .event-section {
                margin: 0 auto 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                gap: 4px;
                width: 100%;
                height: 100%;
        }

        .event-section .event-section-img {
                margin: 0 auto;
                background-repeat: no-repeat;
                background-position: top center;
                background-size: cover;   
                width: 100%;
                height: 45vh;
        }


        .event-section  .mediawrapper{
                width: 100%;
                height: 100%;
                overflow: hidden;
        }

        .event-section   .mediawrapper video{
                width: 100%;
                height: 45vh;
                object-fit: cover;
                object-position:50% 50%;
        }

        .event-section  .event-section-content {
                padding: 8px 15px;
                display: flex;
                flex-direction: column;
                gap: 13px;
                position: relative;
        }

        .event-section-content .event-despcontent {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
}

        .event-section-content .event-despcontent h3{
                text-align: left;
                color: #0F0005;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 1.12px;
        }

        .event-section-content .event-despcontent p {
                margin-top: 8px;
                text-align: left;
                color: #656B76;
                font-family: Montserrat;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                letter-spacing: 0.96px;
        }

        .event-section-content .viewmorebtn {
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
                align-self: flex-end;
                padding: 10px 14px;
                border-radius: 6px;
                background: #040404;
                cursor: pointer;
                color: #FFF;
                font-family: Montserrat;
                font-size: 12px;
                font-weight: 500;
                line-height: 22px;
                letter-spacing: 1px;
                border: 1px solid #040404;

}


