
.aboutuscont {
            margin: 0;
            padding: 0;
            width: 100%;
            height: 100%;

}

.header {     
            position: sticky;
            z-index: 999;
            height: 62px;
            width: 100%;   
            background: #FFF; 
          
}

    .header .headerwrapper {  
                display:flex;
                align-items:center;
                justify-content:space-between;
                padding: 10px 14px;    
                flex-shrink: 0;
    }


        .headerwrapper .headerlogo{
             display: block;
             margin: 0 auto;
        }


        .headerlogo img{
            max-width:130px;
            max-height:40px;
            object-fit: cover;
            object-position:center center;
        }

        .headerwrapper .backbox{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            flex-shrink: 0;
        }

.section-wrapper {
                z-index: 99;
                margin-bottom: 30px;
                padding-top: 58px;
}

        .section {
                        margin: 0 auto 15px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        gap: 4px;
                        width: 100%;
                        height: 100%;

                }

.section .section-img {
                    margin: 0 auto;
                    background-repeat: no-repeat;
                    background-position: top center;
                    background-size: cover;   
                    width: 100%;
                    height: 28vh;                                      
}

        .section  .mediawrapper{
                width: 100%;
                height: 100%;
                overflow: hidden;
        }

        .section  .mediawrapper video{
                width: 100%;
                height: 28vh;
                object-fit: cover;
                object-position:50% 50%;
        }


        .section  .section-content {
                        padding: 8px 15px;
                        display: flex;
                        flex-direction: column;
                        gap: 13px;
                        position: relative;
                      
        }

        .section-content .despcontent {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
            }

        .section-content .despcontent h3{
                    text-align: left;
                    color: #0F0005;
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: 1.12px;
                
        }

       .section-content .despcontent p {
                    margin-top: 8px;
                    text-align: left;
                    color: #656B76;
                    font-family: Montserrat;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                    letter-spacing: 0.96px;
 }

    .section-content .viewmorebtn {
                        align-self: flex-end;
                        padding: 10px 14px;
                        border-radius: 6px;
                        background: #040404;
                        cursor: pointer;
                        color: #FFF;
                        font-family: Montserrat;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 22px;
                        letter-spacing: 1px;
                        border: 1px solid #040404;
    }

/* 
    @media only screen and 
    (min-device-width: 768px) and (max-device-width: 1024px) and (min-resolution: 2dppx),
    (min-device-width: 834px) and (max-device-width: 1194px) and (min-resolution: 2dppx),
    (min-device-width: 1024px) and (max-device-width: 1366px) and (min-resolution: 2dppx) {
  
        .section-content .viewmorebtn {
               
              
        }
        
} */

